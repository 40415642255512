import React from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button} from 'horizon-components-react';
import { Input } from '@material-ui/core';

axios.defaults.headers.get['x-api-key'] = process.env.REACT_APP_API_KEY;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class CheckEmail extends React.Component{
constructor (props) {
  super(props)

  this.state = {
    email: '',
    valid: false,
    open: true,
    scroll: 'paper',
  }
  this.handleEmailChange = this.handleEmailChange.bind(this)
  this.handleSubmit = this.handleSubmit.bind(this)
}

handleSubmit=(event)=>{ // handle calling of axios function
  event.preventDefault();
  
  this.checkUserName(event)

  return(console.log("success"))
}


validateEmail (email) { // validate email syntax
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

handleEmailChange(e) { //handle field input
    const email = e.target.value
    const emailValid = this.validateEmail(email) 

    this.setState({
      email: e.target.value,
      valid: emailValid
    })
}

handleCancel=(e)=>{ // handle calling of axios function

  e.preventDefault();
this.props.history.push("/")
}

 
render(){

  return (
    <div>
      <div class = "overlay" >
      <Dialog
        maxWidth={'xs'}
        fullWidth={'true'}
        open={this.state.open}
        onClose={this.handleClose}
        scroll={this.state.scroll}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle style={{paddingLeft:"7rem"}} id="scroll-dialog-title"><b>Forget Password Form</b></DialogTitle>
        <DialogContent>
          <DialogContentText>
          <form  noValidate autoComplete="off">
      <div>

        <label>
          Username: 
          <Input style={{paddingLeft:"1rem"}} type="text" value={this.state.email} onChange={this.handleEmailChange} error={!this.state.valid}/>  </label>
          <br></br>
          <br></br>
          <div style={{paddingLeft:"10rem"}}>
          <Button contained color="error"  type ="submit" onClick={(e) => this.handleCancel(e)} style={{marginLeft:"0.5rem"}}>
             Cancel
            </Button>
          <Button contained color="success"  type ="submit" disabled={!this.state.valid} onClick={this.handleSubmit} style={{marginLeft:"0.5rem"}}>
             Submit
            </Button>
            </div>
    </div>
    </form>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <div>
          <Button contained color="error" onClick={this.handleClear} style={{marginRight:"0.5rem"}}>
           Clear field
          </Button>
          <Button  contained color="success" onClick={this.handleSubmit}>
            Submit
          </Button>
          </div> */}
        </DialogActions>
      </Dialog>
    </div>
    </div>
  );
}

//onsubmit 

checkUserName=(event)=>{ // check username active in database

    axios.get(`${process.env.REACT_APP_SSO_API}/forgetpassword`,{
        params : {
            "username":this.state.email
        }
    }

).then(res => {
   //console.log(res.data[0])
  // this.props.history.push('/resetpassword2')
  alert('A verification code was sent to: ' + this.state.email);
  this.props.history.push('/resetpassword');
console.log(res.data)
}).catch(err => {
    console.log(err.response); 
    alert( this.state.email + " is not registered");
});

}


}

export default CheckEmail;
import React from "react";
import {Route, Switch} from "react-router-dom";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button} from 'horizon-components-react';
import { Input } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import SimpleAlertMessage from '../component/alertMessage/SimpleAlertMessage';

axios.defaults.headers.common['Content-Type'] = 'application/json';
var passwordValidator = require('password-validator'); // for regex library 

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));



class ResetPassword extends React.Component{
constructor (props) {
  super(props)

  this.state = {
    email: '',
    emailvalid: false,
    newpassword:'',
    newpasswordvalid:false,
    confirmnewpassword:'',
    confirmnewpasswordvalid:false,
    verificationcode:'',
    verificationcodevalid:false,
    open: true,
    scroll: 'paper',
    allvalid:false,
    showPassword:false,
    showNewPassword:false,
    alert:null,
    isAlert:false,
  }
  // this.handleEmailChange = this.handleEmailChange.bind(this)
  // this.handlePasswordChange = this.handlePasswordChange.bind(this)
  // this.handleSubmit = this.handleSubmit.bind(this)
  // this.handleChange = this.handleChange.bind(this);
  // this.resetPassword=this.resetPassword.bind(this)
}

handleClickShowPassword = () => this.setState({showPassword:!this.state.showPassword}); //handles hide password in input field
handleClickShowNewPassword = () => this.setState({showNewPassword:!this.state.showNewPassword}); //handles hide password in input field
handleMouseDownPassword = (event) => event.preventDefault(); //handles hide password in input field

sendAlert=(title,classType,timeout)=> //handle alert message banner
{
    var temp={title:title,classType:classType}
    this.setState({alert:temp},()=>{this.setState({isAlert:true})}) //sets alert values, then shows alert
    setTimeout(()=>this.setState(this.setState({isAlert:false})),timeout) //waits "timeout" seconds. then hides alert
}


handleSubmit=(e)=>{ // handle calling of axios function

  e.preventDefault();
  if(this.state.allvalid && (this.state.newpassword=== this.state.confirmnewpassword))
  
   this.resetPassword()

  console.log("success")
}


handleCancel=(e)=>{ // handle calling of axios function

  e.preventDefault();
this.props.history.push("/")
}


validateEmail (email) {  // validate email syntax
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}


validateNewPassword (newpassword) { // validate newpassword syntax
  var schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(); 
console.log(schema.validate(newpassword))
return schema.validate(newpassword)

}
validateConfirmNewPassword (confirmnewpassword) { // validate confirmnewpassword syntax
  var schema = new passwordValidator();
  schema.is().min(8).has().uppercase().has().lowercase().has().digits(); 
console.log(schema.validate(confirmnewpassword))
return schema.validate(confirmnewpassword)

}

validateVerificationCode (verificationcode) { // validate verification code syntax
  var schema = new passwordValidator();
  schema.is().min(6).has().digits(); 
console.log(schema.validate(verificationcode))
return schema.validate(verificationcode)
}



handleChange = (event)=>{
  // check it out: we get the evt.target.name (which will be either "email" or "password")
  // and use it to target the key on our `state` object with the same name, using bracket syntax

 
  this.setState({confirmnewpasswordvalid:false, allvalid:false, [event.target.name]: event.target.value},() => this.evalchange())
}

evalchange=()=> //evaluate whether individual fields are valid and setting the respectiive states
{
  const emailvalid = this.validateEmail(this.state.email)
  const newpasswordvalid = this.validateNewPassword(this.state.newpassword) 
  const confirmnewpasswordvalid = this.validateConfirmNewPassword(this.state.confirmnewpassword) 
  const verificationcodevalid = this.validateVerificationCode(this.state.verificationcode) 
  if(this.state.confirmnewpassword ===  this.state.newpassword)
  this.setState({ emailvalid, newpasswordvalid,confirmnewpasswordvalid, verificationcodevalid},()=>this.checkAllFields())
}

checkAllFields=()=> // check ALL fields are valid before enabling submit button
{

  if(this.state.emailvalid && this.state.newpasswordvalid && this.state.confirmnewpasswordvalid && this.state.verificationcodevalid && this.state.newpassword === this.state.confirmnewpassword)
  {  this.setState({allvalid:true})
  }
}


render(){


  return (

    <div>
 
      <div class = "overlay" >
      <Dialog
        maxWidth={'xs'}
        fullWidth={'true'}
        open={this.state.open}
        onClose={this.handleClose}
        scroll={this.state.scroll}
        aria-labelledby="scroll-dialog-title"
      >
           <div >{this.state.isAlert && <SimpleAlertMessage title={this.state.alert.title} classType ={this.state.alert.classType}/> }</div>

        <DialogTitle style={{paddingLeft:"7rem"}} id="scroll-dialog-title"><b>Reset Password Form</b></DialogTitle>
        <DialogContent>
          <DialogContentText>
          <form  noValidate autoComplete="off">
      <div>
      <Grid container spacing={3}  
  alignItems='center'  direction='column'>
      {/* <form >  */}
        
        <Grid  item xs={6}>
        <label>
          Username: 
          <Input style={{paddingLeft:"1rem"}} name="email" type="text" value={this.state.email} onChange={this.handleChange} error={!this.state.emailvalid}/>  </label>
          </Grid>
          <Grid item xs={6}>
          <label>
          New password: 
          <Input id="standard-adornment-password" type={this.state.showPassword ? "text" : "password"}  style={{paddingLeft:"1rem"}} name ="newpassword"  endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }value={this.state.newpassword} onChange={this.handleChange} error={!this.state.newpasswordvalid}/>  </label>
          </Grid>

          <Grid item xs={6}>
          <label>
          Confirm New Password: 
          <Input   id="standard-adornment-password" type={this.state.showNewPassword ? "text" : "password"} style={{paddingLeft:"1rem"}} name="confirmnewpassword" endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowNewPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            } value={this.state.confirmnewpassword} onChange={this.handleChange} error={!this.state.confirmnewpasswordvalid}/>  </label>
          </Grid>

          <Grid item xs={6}>
          <label>
          Verification Code: 
          <Input style={{paddingLeft:"1rem"}} name = "verificationcode" type="text" value={this.state.verificationcode} onChange={this.handleChange} error={!this.state.verificationcodevalid}/>  </label>
          </Grid>

        
        
      {/* </form> */}
      </Grid>
    </div>
    </form>
    <br></br>
       <div style={{paddingLeft:'10rem'}}>
      <Button style={{paddingLeft:'10rem'}} contained color="error"  type ="submit" onClick={(e) => this.handleCancel(e)} style={{marginLeft:"0.5rem"}}>
             Cancel
            </Button>
          <Button  style={{paddingLeft:'10rem'}} contained color="success" disabled={!this.state.allvalid} type ="submit" onClick={(e) => this.handleSubmit(e)} style={{marginLeft:"0.5rem"}}>
             Submit
            </Button>
            </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <div>
          <Button contained color="error" onClick={this.handleClear} style={{marginRight:"0.5rem"}}>
           Clear field
          </Button>
          <Button  contained color="success" onClick={this.handleSubmit}>
            Submit
          </Button>
          </div> */}
        </DialogActions>
      </Dialog>
    </div>
    </div>
  );
}

//onsubmit 

resetPassword=()=>{   // axios post call to reset password with verification code

    axios.post(`${process.env.REACT_APP_SSO_API}/resetpassword`,{
    
        username:this.state.email, 
        newpassword:this.state.newpassword,
        confirmnewpassword:this.state.confirmnewpassword,
        verificationcode:this.state.verificationcode
    
}
).then(res => {
  //console.log(res.data[0])
//   this.props.history.push({pathname:'/auth', newRoleToken:res.data[0].roletoken})
this.sendAlert(res.response.data.message.message,"SuccessAlert",2000);
console.log(res.response.data.message.message)

return 1;

}).catch(err => {
 this.sendAlert(err.response.data.message.message,"ErrorAlert",2000);
console.log(err.response.data.message.message); 
return 1;
});

}


}

export default ResetPassword;